"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const plugins = {};
const Plugins = {
    register(name, constructor) {
        plugins[name] = constructor;
    },
    get(name) {
        if (typeof plugins[name] === "function") {
            return plugins[name];
        }
        return null;
    },
    all() {
        return plugins;
    }
};
window.Plugins = Plugins;
exports.default = Plugins;
